// import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "SandwichApplication";

export const loadFourYearSandwichApplicationUrl = (rrr) =>
	`${baseUrl}/load-four-year-application-form?rrr=${rrr}`;
export const storeFourYearSandwichApplicationPersonalDetailsUrl = () =>
	`${baseUrl}/store-four-year-sandwich-application-personal-details`;
export const storeFourYearSandwichApplicationProgrammeDetailsUrl = () =>
	`${baseUrl}/store-four-year-sandwich-application-programme-details`;
export const storeFourYearSandwichApplicationOlevelDetailsUrl = () =>
	`${baseUrl}/store-four-year-sandwich-application-olevel-details`;
export const storeFourYearSandwichApplicationEmploymentDetailsUrl = () =>
	`${baseUrl}/store-four-year-sandwich-application-employment-details`;

export const loadFiveYearSandwichApplicationUrl = (rrr) =>
	`${baseUrl}/load-five-year-application-form?rrr=${rrr}`;
export const storeFiveYearSandwichApplicationPersonalDetailsUrl = () =>
	`${baseUrl}/store-five-year-sandwich-application-personal-details`;
export const storeFiveYearSandwichApplicationProgrammeDetailsUrl = () =>
	`${baseUrl}/store-five-year-sandwich-application-programme-details`;
export const storeFiveYearSandwichApplicationOlevelDetailsUrl = () =>
	`${baseUrl}/store-five-year-sandwich-application-olevel-details`;
export const storeFiveYearSandwichApplicationEmploymentDetailsUrl = () =>
	`${baseUrl}/store-five-year-sandwich-application-employment-details`;
export const storeFiveYearSandwichApplicationEducationHistoryDetailsUrl = () =>
	`${baseUrl}/store-five-year-sandwich-application-education-history-details`;
export const addOrUpdateSandwichCertificateUrl = () =>
	`${baseUrl}/add-or-update-sandwich-certificate`;
export const storeFiveYearSandwichApplicationEducationQualificationDetailsUrl =
	() =>
		`${baseUrl}/store-five-year-sandwich-application-educational-qualifications`;
