import { Link } from "react-router-dom";
import { Accordion } from "../../../../ui_elements";
import styles from "../styles.module.css";

export const Jupeb = ({ handleCurrentLink, applicationForms }) => {
	return (
		<>
			<Accordion defaultState={true} title={"JUPEB"}>
				<p className="text-left mt-3">
					Please select a link applicable to you from the list below
				</p>
				<div className={`${styles.cardLinks} mt-2`}>
					<Link to={() => handleCurrentLink(applicationForms.JUPEB)}>
						Generate Invoice
					</Link>
					<Link to="/jupeb_login">Application Form</Link>
				</div>
			</Accordion>
		</>
	);
};
