import React from "react";
import logo from "../../assets/images/logo.png";
import "./pageLoader.css";

export const PageLoader = () => {
	return (
		<div className="page_loader_container">
			<div className="image_wrapper">
				<img src={logo} alt="logo" className="main_logo" />
				<div className="loader" />
			</div>
		</div>
	);
};
