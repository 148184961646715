import { Link } from "react-router-dom";
import { Accordion } from "../../../../ui_elements";
import styles from "../styles.module.css";

export const Undergraduate = ({ handleCurrentLink, applicationForms }) => {
	return (
		<>
			<Accordion defaultState={true} title={"Post UTME"}>
				<p className="text-left mt-3">
					Please select a link applicable to you from the list below
				</p>
				<div className={`${styles.cardLinks} mt-2`}>
					<Link to={() => handleCurrentLink(applicationForms.UTME)}>
						Generate Invoice
					</Link>
					<Link to="/putme_login">Application Form</Link>
				</div>
			</Accordion>
			<Accordion title={"Direct Entry"}>
				<p className="text-left mt-3">
					Please select a link applicable to you from the list below
				</p>
				<div className={`${styles.cardLinks} mt-2`}>
					<Link
						to={() =>
							handleCurrentLink(applicationForms.directEntry)
						}
					>
						Generate Invoice
					</Link>
					<Link to={"/direct_entry_login"}>Application Form</Link>
					<a
						href={
							"http://unn.tenecesupports.com/unn_app/Modules/Applications/PUTME/PUTMEResults/CheckDEResults.aspx"
						}
						target="_blank"
						rel="noreferrer"
					>
						Check DE Results
					</a>
				</div>
			</Accordion>
			<Accordion title={"Inter University"}>
				<p className="text-left mt-3">
					Please select a link applicable to you from the list below
				</p>
				<div className={`${styles.cardLinks} mt-2`}>
					<Link
						to={() =>
							handleCurrentLink(
								applicationForms.interUniversityTransfer
							)
						}
					>
						Generate Invoice
					</Link>
					<Link to={"/uni_transfer_login"}>Application Form</Link>
				</div>
			</Accordion>
			<Accordion title={"Staff Request"}>
				<p className="text-left mt-3">
					Please select a link applicable to you from <br /> the list
					below
				</p>
				<div className={`${styles.cardLinks} mt-2`}>
					<Link
						to={() =>
							handleCurrentLink(applicationForms.staffRequestForm)
						}
					>
						Generate Invoice
					</Link>
					<Link to={"/staff_request_login"}>Application Form</Link>
				</div>
			</Accordion>
			<Accordion title={"Admission Shopping"}>
				<p className="text-left mt-3">
					Please select a link applicable to you from <br /> the list
					below
				</p>
				<div className={`${styles.cardLinks} mt-2`}>
					<Link
						to={() =>
							handleCurrentLink(applicationForms.supplementary)
						}
					>
						Generate Invoice
					</Link>
					<Link to={"/supplementary_login"}>Application Form</Link>
				</div>
			</Accordion>
		</>
	);
};
