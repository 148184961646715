export const SEMESTERS = [
	{
		label: "First Semester",
		value: 1
	},
	{
		label: "Second Semester",
		value: 2
	}
];

export const SCHOOL_DETAILS = {
	name: "University of Nigeria, Nsukka",
	nameWithoutCampus: "University of Nigeria",
	location: "Enugu State",
	pmb: "Obukpa Rd, 410105, Nsukka, Enugu",
	pmbWithoutState: "Obukpa Rd, 410105",
	pmbStateOnly: "Nsukka, Enugu",
	shortForm: "UNN",
	viceChancellor:
		"PROFESSOR POLYCARP EMEKA CHIGBU B.Sc., M. Sc. (Nig.), Ph.D (London), FRSS, FNSA - (Ag. Vice-Chancellor)",
	registrar: "DR MRS NGOZI NNEBEDUM FIIA. FCAI. MAIEA. MAUA(UK)"
};

export const PAYMENTTYPES = [
	{
		label: "Full Payment",
		value: "Full"
	},
	{
		label: "First Installment",
		value: "FirstInstallment"
	},
	{
		label: "Second Installment",
		value: "SecondInstallment"
	}
];

export const EXTERNAL_STUDENT_PAYMENT_DETAILS = {
	paymentPurpose: "External",
	paymentType: "Full"
};

export const PAYMENTIDENTIFIER = {
	schoolFees: 1,
	acceptance: 2,
	hostelArrears: 16,
	hostel: "Hostel",
	sundry: 9,
	changeOfDepartment: "ChangeOfDepartment"
};

export const PAGESIZE = {
	sm: 5,
	md: 10,
	lg: 15,
	xl: 20,
	xxl: 30
};

export const SEARCH_DELAY = {
	sm: 300,
	md: 500,
	lg: 800,
	xl: 1000
};

export const INITIAL_DATE = "0001-01-01T00:00:00";

export const MINIMUM_AGE = 15;
export const MAXIMUM_AGE = 80;
export const TENECE_SUPPORT_URL = "https://teneceschoolsupport.com/";
export const UNN_PG_HOSTEL_APPLICATION_LINK =
	"https://schmgr.unn.edu.ng/PG_LoginHostel.aspx";
export const UNN_HOSTEL_APPLICATION_LINK =
	"https://schmgr.unn.edu.ng/LoginHostel.aspx?sent=e01a1733-1f93-4214-b6a5-7964ae2eda24";
// Authentication Constants
export const TOKEN_HOLDER = `${SCHOOL_DETAILS.shortForm}token`;
export const USER_ROLE_HOLDER = `${SCHOOL_DETAILS.shortForm}userRole`;
export const USER_NAME_HOLDER = `${SCHOOL_DETAILS.shortForm}userName`;
export const BIRTHDAY_STATE_HOLDER = `${SCHOOL_DETAILS.shortForm}birthday`;
export const STUDENT_TYPE_HOLDER = `${SCHOOL_DETAILS.shortForm}studentType`;
export const REFRESH_TOKEN_HOLDER = `${SCHOOL_DETAILS.shortForm}refreshToken`;
export const USER_TYPES = ["lecturer", "student", "official"];

//Application mode id
export const APPLICATION_MODE = {
	UTME: 8,
	JUPEB: 5,
	DIRECT_ENTRY: 33,
	PG: 7,
	SUPPLEMENTARY: 3,
	PRE_DEGREE: 2,
	CCE: 4,
	STAFF_REQUEST: 16,
	FOUR_YEAR_SANDWICH: 17,
	FIVE_YEAR_SANDWICH: 9,
	INTER_UNIVERSITY_TRANSFER: 2,
	DIPLOMA: 1,
	SANDWICH_PROGRAMME: 17
};

export const APPLICATION_ID = {
	UTME: 8,
	JUPEB: 5,
	DIRECT_ENTRY: 33,
	PG: 7,
	SUPPLEMENTARY: 15,
	PRE_DEGREE: 10,
	CCE: 16,
	STAFF_REQUEST: 16,
	FOUR_YEAR_SANDWICH: 17,
	FIVE_YEAR_SANDWICH: 9,
	INTER_UNIVERSITY_TRANSFER: 2,
	DIPLOMA: 1,
	SANDWICH_PROGRAMME: 17,
	PUTME_SLIP: 24
};

export const STUDENT_TYPES = {
	UNDERGRADUATE: 1,
	JUPEB: 8,
	Diploma: 3,
	POSTGRADUATE: 2,
	Sandwich: 9,
	"Sandwich Diploma": 10,
	INTER_UNIVERSITY_TRANSFER: 2
};

export const POST_GRADUATE_DETAILS = {
	deputyRegister: "MRS. ANTHONIA AMADI",
	correspondence:
		"Provost, College of Postgraduate Studies, University of Nigeria, Nsukka.",
	correspondenceEmail: "provost.cpgsunn@unn.edu.ng",
	provost: "PROF. OBIOMA U. NJOKU",
	provostEmail: "obioma.njoku@unn.edu.ng"
};

export const CertificateTypeIds = {
	NCE: 3,
	ACE: 9,
	TC: 10
};
