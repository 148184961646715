import { Fragment } from "react";
import "./style.css";

export const ResultTable = ({
	subjects = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
	students = [
		{
			name: "NWANKWO CHIDIMMA JUDITH",
			reg: 2013316001,
			subjects: [
				{
					gr: "A",
					gp: "11.00"
				},
				{
					gr: "A",
					gp: "11.00"
				},
				{
					gr: "A",
					gp: "11.00"
				},
				{
					gr: "A",
					gp: "11.00"
				},
				{
					gr: "A",
					gp: "11.00"
				},
				{
					gr: "A",
					gp: "11.00"
				},
				{
					gr: "A",
					gp: "11.00"
				},
				{
					gr: "A",
					gp: "11.00"
				},
				{
					gr: "A",
					gp: "11.00"
				},
				{
					gr: "A",
					gp: "11.00"
				},
				{
					gr: "A",
					gp: "11.00"
				},
				{
					gr: "A",
					gp: "11.00"
				}
			]
		}
	]
}) => {
	// const outStandings = (array) => {
	// 	let courses = array.map((course, _) => course?.courseCode);

	// 	if (courses.length > 0) {
	// 		return courses.join(",");
	// 	} else {
	// 		return "NONE";
	// 	}
	// };

	return (
		<table className="result-table-th">
			<thead>
				<tr>
					<td></td>
					<td></td>
					<td></td>
					{subjects.map((sub, i) => (
						<td
							key={i + 200000}
							colSpan="2"
							className="vertical-td course-fields"
						>
							{/* {sub?.courseTitle}
							<br /> */}
							{sub?.courseCode}
						</td>
					))}
					<td colSpan="3" rowSpan="1" className="td-align-center">
						CURRENT
					</td>
					<td colSpan="3" rowSpan="1" className="td-align-center">
						PREVIOUS
					</td>
					<td colSpan="3" rowSpan="1" className="td-align-center">
						CUMULATIVE
					</td>
					<td rowSpan={2} className="td-align-center vertical-td">
						REM
					</td>
				</tr>
				<tr>
					<td></td>
					<td></td>
					<td>CREDIT UNIT</td>
					{subjects?.map((cred, i) => (
						<td className="td-align-center" colSpan="2" key={i}>
							{cred?.courseUnit}
						</td>
					))}
					<td rowSpan={2} className="vertical-td">
						TNU
					</td>
					<td rowSpan={2} className="vertical-td">
						TCP
					</td>
					<td rowSpan={2} className="vertical-td">
						GPA
					</td>

					<td rowSpan={2} className="vertical-td">
						TNU
					</td>
					<td rowSpan={2} className="vertical-td">
						TCP
					</td>
					<td rowSpan={2} className="vertical-td">
						GPA
					</td>

					<td rowSpan={2} className="vertical-td">
						TNU
					</td>
					<td rowSpan={2} className="vertical-td">
						TCP
					</td>
					<td rowSpan={2} className="vertical-td">
						GPA
					</td>
				</tr>
				<tr>
					<td>SN</td>
					<td className="whitespace-nowrap">REF NO</td>
					<td className="whitespace-nowrap">
						STUDENT NAME AND REG NO
					</td>
					{subjects?.map((cred, i) => (
						<td
							key={i + 1000000}
							className="td-align-center whitespace-nowrap"
							colSpan="2"
						>
							GR
						</td>
					))}
				</tr>
			</thead>
			<tbody>
				{students?.map((students) => (
					<Fragment key={students.id}>
						<tr>
							<td rowSpan="2">{students.id}</td>
							<td rowSpan="2">{students.regNo}</td>
							<td rowSpan="2">{students.name}</td>
							{students?.subjects?.map((stud) => (
								<td
									rowSpan="2"
									colSpan={"2"}
									className={`text-right ${
										stud.grade === "F"
											? "red_color_text"
											: ""
									}`}
								>
									{stud.grade ? stud.grade : "-"}
								</td>
							))}
							<td rowSpan={2} className="td-align-center">
								{students?.currentSemesterDataResponse
									?.creditUnit || "-"}
							</td>
							<td rowSpan={2} className="td-align-center">
								{
									students?.currentSemesterDataResponse
										?.creditPoint
								}
							</td>
							<td rowSpan={2} className="td-align-center">
								{
									students?.currentSemesterDataResponse
										?.gradePointAverage
								}
							</td>
							<td rowSpan={2} className="td-align-center">
								{students?.previousSemesterDataResponse
									?.creditUnit || "-"}
							</td>
							<td rowSpan={2} className="td-align-center">
								{
									students?.previousSemesterDataResponse
										?.creditPoint
								}
							</td>
							<td rowSpan={2} className="td-align-center">
								{
									students?.previousSemesterDataResponse
										?.gradePointAverage
								}
							</td>
							<td rowSpan={2} className="td-align-center">
								{students?.cumulativeSemesterDataResponse
									?.creditUnit || "-"}
							</td>
							<td rowSpan={2} className="td-align-center">
								{
									students?.cumulativeSemesterDataResponse
										?.creditPoint
								}
							</td>

							<td rowSpan={2} className="td-align-center  ">
								{
									students?.cumulativeSemesterDataResponse
										?.gradePointAverage
								}
							</td>
							<td rowSpan={2} className="td-align-center">
								{students?.remark}
							</td>
						</tr>
						<br />
						{/* <br /> */}
						{/* <tr>
							<td colSpan={subjects.length * 2}>
								<strong className="font-size-for-out">
									OUTSTANDING:
								</strong>
								&nbsp;
								{outStandings(students?.outStandingCourses)}
							</td>
						</tr> */}
					</Fragment>
				))}
			</tbody>
		</table>
	);
};
