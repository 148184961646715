import { Link } from "react-router-dom";
import { Accordion } from "../../../../ui_elements";
import styles from "../styles.module.css";

export const Sandwich = ({ handleCurrentLink, applicationForms }) => {
	return (
		<>
			<Accordion defaultState={true} title={"4-Year Sandwich Programme"}>
				<p className="text-left mt-3">
					Please select a link applicable to you from the list below
				</p>
				<div className={`${styles.cardLinks} mt-2`}>
					<Link
						to={() =>
							handleCurrentLink(applicationForms.fourYearSandwich)
						}
					>
						Generate Invoice
					</Link>
					<Link to="/four_year_sandwich_login">Application Form</Link>
				</div>
			</Accordion>
			<Accordion defaultState={true} title={"5-Year Sandwich Programme"}>
				<p className="text-left mt-3">
					Please select a link applicable to you from the list below
				</p>
				<div className={`${styles.cardLinks} mt-2`}>
					<Link
						to={() =>
							handleCurrentLink(applicationForms.fiveYearSandwich)
						}
					>
						Generate Invoice
					</Link>
					<Link to="/five_year_sandwich_login">Application Form</Link>
				</div>
			</Accordion>
		</>
	);
};
