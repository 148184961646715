import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Appraisal";

export const getAppraisalsUrl = (filter) =>
	`${baseUrl}?${generateUrlParams(filter)}`;
export const getStudentAppraisalsUrl = () => `${baseUrl}/student-appraisals`;
export const getAppraisalQuestionsUrl = () => `${baseUrl}/question`;
export const evaluateCourseUrl = () => `${baseUrl}/course-evaluation`;
export const getAppraisalReportUrl = (filter) =>
	`${baseUrl}/report?${generateUrlParams(filter)}`;
export const downloadAppraisalReportUrl = (filter) =>
	`${baseUrl}/download-appraisal-report?${generateUrlParams(filter)}`;
