import { useMemo } from "react";
import { Route } from "react-router-dom";
import { TabLayout } from "../../ui_elements";

export const HostelRouter = ({
	component,
	path,
	exact,
	purpose,
	title,
	user,
	...rest
}) => {
	const Component = component;
	const paths = useMemo(
		() => [
			{
				title: "Manage Hostels",
				path: "/hostel_management/manage_hostel",
				disabled: false
			},
			{
				title: "Manage Category",
				path: "/hostel_management/manage_category",
				disabled: false
			},
			{
				title: "Manage Reservations",
				path: "/hostel_management/manage_reservation",
				disabled: false
			}
		],
		[]
	);
	return (
		<Route
			exact={exact}
			path={path}
			{...rest}
			render={(props) => {
				return (
					<TabLayout title={title} paths={paths}>
						<div className="p-4 p-lg-0">
							<Component {...rest} {...props} />
						</div>
					</TabLayout>
				);
			}}
		/>
	);
};
