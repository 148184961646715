
import { generateUrlParams } from '../../utils/generateUrlParams';

const baseUrl = "SchoolProgramme";


export const getAllSchoolProgrammesUrl = (filter) => `${baseUrl}/get-all-school-programes?${generateUrlParams(filter)}`
export const toggleProgrammeActivationStatusUrl = (id) => `${baseUrl}/toggle-school-programme-activation?programmeId=${id}`
export const createSchoolProgrammeUrl = () => `${baseUrl}/create-school-programme`
export const downloadProgrammeUploadTemplateUrl = () => `${baseUrl}/download-programme-upload-template`
export const uploadSchoolProgrammeUrl = () => `${baseUrl}/upload-school-programmes`
export const updateSchoolProgrammeUrl = () => `${baseUrl}/update-school-programme`
export const deleteSchoolProgrammeUrl = (id) => `${baseUrl}/delete-school-programme?programmeId=${id}`