import { useState, useEffect } from "react";
import styles from "./banner.module.css";
import { Close, Hurray } from "../../../assets/svgs";

const NotificationBanner = () => {
	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		const now = new Date();

		// Static start and end dates
		// const startDate = new Date("2024-10-07T00:00:00"); // October 7th
		const endDate = new Date("2024-10-12T23:59:59"); // October 12th

		// Hide the banner if current date is after the end date
		if (now > endDate) {
			setIsVisible(false);
		}
	}, []);

	// Close banner manually
	const handleClose = () => {
		setIsVisible(false);
	};

	if (!isVisible) {
		return <> </>;
	}

	return (
		<section className={styles.notification_banner}>
			<div className={styles.content}>
				<div className={styles.checking}>
					<Hurray />
					<b className={styles.bold_content}>
						Celebrating Customer Service Week with YOU in mind!
					</b>
				</div>
				<div>
					Got portal problems? No worries—our team is on it like
					superheroes, ready to save the day! Shoot us a message.
				</div>
			</div>
			<div className={styles.button}>
				<button className={styles.close_btn} onClick={handleClose}>
					<Close />
				</button>
			</div>
		</section>
	);
};

export default NotificationBanner;
