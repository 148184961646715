import { useContext, useState } from "react";
import logo from "../../assets/images/sideLogo.png";
import globalMenuIcon from "../../assets/svgs/globalMenuIcon.svg";
import cancel from "../../assets/svgs/cancel.svg";
import Avatar from "react-avatar";
import "./globalMenu.css";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "../button/Button";
import {
	APPLICATION_ID,
	APPLICATION_MODE,
	TENECE_SUPPORT_URL,
	UNN_HOSTEL_APPLICATION_LINK,
	UNN_PG_HOSTEL_APPLICATION_LINK
	// UNN_HOSTEL_APPLICATION_LINK
} from "../../utils/constants";
import useComponentVisible from "../../custom-hooks/useComponentIsVisible";
import { checkIfChristmasPeriod } from "../../utils/checkIfChristmasPeriod";
import ChristmasLogo from "../../assets/images/christmasLogo.png";
import { ProfileContext, Spinner } from "..";
import { LinkDropdown } from "../linkDropdown/linkDropdown";
import { Bell, LinkIcon } from "../../assets/svgs";
import { timeAgo } from "../../utils/formatDate";
import { getAllRecentNotificationUrl } from "../../api/urls";
import { useApiGet } from "../../api/apiCall";
import useHandleNotification from "../../custom-hooks/useHandleNotification";

const GlobalMenu = ({
	title = "Components",
	openSide = () => {},
	setSignOutModal,
	userName,
	isLanding,
	isPg
}) => {
	const { push } = useHistory();
	const [menu, setMenu] = useState(false);
	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);
	const { handNotificationAction } = useHandleNotification();

	const linkOptions = [
		{
			name: "Home",
			route: "/"
		},
		{
			name: "Bursary Collections",
			route: "/bursary_collection/all"
		},

		{
			name: "Postgraduate Portal",
			route: "/post_graduate_portal"
		}
	];

	const pg_application_links = [
		{
			name: "Generate Invoice",
			link: {
				pathname: `/pg_students_invoice`,
				state: {
					application: {
						applicationMode: APPLICATION_MODE.PG,
						id: APPLICATION_ID.PG,
						name: "Postgraduate Application"
					}
				}
			}
		},
		{
			name: "Application Form",
			link: "/pg_login"
		},
		{
			name: "Check Results",
			link: "/"
		}
	];

	const pg_ACE_SPED_Application = [
		{
			name: "Generate Invoice",
			link: {
				pathname: `/pg_students_invoice`,
				state: {
					application: {
						applicationMode: APPLICATION_MODE.PG,
						id: 28,
						name: "ACE-SPED APPLICATION"
					}
				}
			}
		},
		{
			name: "Application Form",
			link: "/pg_login"
		}
	];

	const pg_CBN_CPP_Application = [
		{
			name: "Generate Invoice",
			link: {
				pathname: `/pg_students_invoice`,
				state: {
					application: {
						applicationMode: APPLICATION_MODE.PG,
						id: 31,
						name: "CBN-CPP APPLICATION"
					}
				}
			}
		},
		{
			name: "Application Form",
			link: "/pg_login"
		}
	];

	const data = useContext(ProfileContext);

	const {
		data: recentNotifications,
		isLoading: isLoadingRecentNotifications
	} = useApiGet(getAllRecentNotificationUrl(), {
		enabled: !!userName,
		keepPreviousData: true,
		refetchOnWindowFocus: false
	});

	let recentUnreadNotifications = recentNotifications?.data?.filter(
		({ isRead }) => !isRead
	)?.length;

	// const handNotificationAction = (item) => {
	// 	if (item.isRead) {
	// 		handleNotificationRedirect(item);
	// 		return;
	// 	}

	// 	handleNotificationRedirect(item);
	// 	// const requestDet = {
	// 	// 	url: readNotificationUrl(item.id)
	// 	// };
	// 	// mutate(requestDet, {
	// 	// 	onSuccess: () => {
	// 	// 		queryClient.invalidateQueries({
	// 	// 			predicate: (query) =>
	// 	// 				query.queryKey.startsWith("Notification/")
	// 	// 		});
	// 	// 	},
	// 	// 	onError: ({ response }) => {
	// 	// 		throw response;
	// 	// 	}
	// 	// });
	// };

	// const handleNotificationRedirect = (item) => {
	// 	if (item.notificationCategory === "courseApproval") {
	// 		push({
	// 			pathname: "/course_registration/view",
	// 			state: item.notificationRequestProperties
	// 		});
	// 	}
	// };
	return (
		<section className="red-global-menu">
			<div className="d-flex align-items-center">
				{isLanding !== "unAuthenticated" ? (
					<button className="red-hamburger-menu" onClick={openSide}>
						<img src={globalMenuIcon} alt="open side menu" />
					</button>
				) : (
					<button
						className="red-hamburger-menu responsive-hamburger-menu"
						onClick={() => setMenu(true)}
					>
						<img src={globalMenuIcon} alt="open side menu" />
					</button>
				)}
				<div
					className="red-global-logo"
					role={"button"}
					onClick={() => push("/")}
				>
					{checkIfChristmasPeriod() ? (
						<>
							<img src={ChristmasLogo} alt="UNN Christmas logo" />
							<img src={ChristmasLogo} alt="UNN Christmas logo" />
						</>
					) : (
						<>
							<img src={logo} alt="UNN logo" />
							<img src={logo} alt="UNN logo" />
						</>
					)}
				</div>
				{isLanding === "unAuthenticated" ? (
					isPg ? (
						<div
							className={`pg_landing_links red-current-module global-landing-page-link-container gap-3 ${
								menu && `retract-navigation`
							}`}
						>
							<button
								onClick={() => setMenu(false)}
								className="cancel-button"
							>
								<img src={cancel} alt="" />
							</button>
							<button
								className="global-landing-page-links"
								onClick={() => push("/")}
							>
								Home
							</button>
							<LinkDropdown
								className={"pg_drop_btn"}
								linkName={"PG Applications"}
								buttonGroup={pg_application_links}
							/>
							<LinkDropdown
								className={"pg_drop_btn"}
								linkName={"CBN-CPP Application"}
								buttonGroup={pg_CBN_CPP_Application}
							/>
							<LinkDropdown
								className={"pg_drop_btn"}
								linkName={"ACE-SPED Application"}
								buttonGroup={pg_ACE_SPED_Application}
							/>
							<a href={UNN_PG_HOSTEL_APPLICATION_LINK} target="_blank" rel="noreferrer">
								<button className="global-landing-page-links">
									Apply for PG Hostel
								</button>
							</a>
						</div>
					) : (
						<div
							className={`red-current-module global-landing-page-link-container ${
								menu && `retract-navigation`
							}`}
						>
							<button
								onClick={() => setMenu(false)}
								className="cancel-button"
							>
								<img src={cancel} alt="" />
							</button>

							{linkOptions.map((link, i) => (
								<button
									key={i}
									className="global-landing-page-links"
									onClick={() => push(link.route)}
								>
									{link.name}
								</button>
							))}
							<a href={UNN_HOSTEL_APPLICATION_LINK} target="_blank" rel="noreferrer">
								<button className="global-landing-page-links">
									Apply For UG Hostel
								</button>
							</a>
						</div>
					)
				) : (
					<div className="red-current-module hide-red-current-module">
						{title}
					</div>
				)}
			</div>
			{isLanding !== "unAuthenticated" ? (
				<div className="d-flex dropdown">
					{userName ? (
						<section
							className="gm-notification-container"
							ref={ref}
						>
							<button
								onClick={() =>
									setIsComponentVisible(!isComponentVisible)
								}
							>
								<Bell />
								{recentUnreadNotifications ? (
									<span className="gm-notification-breathing-light">
										{recentUnreadNotifications}
									</span>
								) : (
									""
								)}
							</button>
							<div
								ref={ref}
								className={`gm-notification-contents gm-notification-contents-style ${
									isComponentVisible ? "d-block" : "d-none"
								}`}
							>
								<div className="d-flex justify-content-between align-items-center border-bottom px-3 pt-3">
									<h3>
										Notifications(
										{recentNotifications?.data?.length})
									</h3>
									<Link
										to="/notifications"
										className="gm-view-all"
									>
										View All Notifications
									</Link>
								</div>
								<div className="gm-notification-items pt-3">
									{isLoadingRecentNotifications ? (
										<Spinner />
									) : (
										<ul>
											{recentNotifications?.data?.map(
												(item, index) => (
													<li
														key={index}
														className={`py-2 border-bottom px-3 ${
															item?.isRead
																? "gm-notification-read"
																: ""
														}`}
														role="button"
														onClick={() =>
															handNotificationAction(
																item
															)
														}
													>
														<div className="pr-4">
															<h4>
																{
																	item?.notificationType
																}
															</h4>
															<p className="my-1">
																{item?.message}
															</p>
															<p>
																{timeAgo(
																	item?.dateCreated
																)}
															</p>
														</div>
													</li>
												)
											)}
										</ul>
									)}
									<div className="py-4 text-center">
										That's all your notifications from the
										last 7 days.
									</div>
								</div>
							</div>
						</section>
					) : (
						""
					)}
					<section
						id="navDrop"
						data-toggle="dropdown"
						role="button"
						data-cy="open_avatat"
					>
						<Avatar
							name={userName ?? "Guest"}
							round
							size="32"
							className="global-avatar"
							maxInitials={2}
							src={data?.profileData?.personalData?.passport}
						/>
					</section>
					<section className="dropdown-menu res-role">
						<h3 className="res-user">Account</h3>
						<div className="res-wrapper">
							<div className=" res-roles w-100 d-flex justify-content-start align-items-center">
								<Avatar
									name={userName ?? "Guest"}
									round
									size="32"
									className="global-avatar mr-2"
									maxInitials={2}
									src={
										data?.profileData?.personalData
											?.passport
									}
								/>
								<div>
									<h3 className="res-profile-name">
										{userName?.toUpperCase() ?? "guest"}
									</h3>
									<h3 className="res-profile-detail mt-1">
										{
											data?.profileData?.programmeDetail
												?.level
										}
									</h3>
								</div>
							</div>

							<div className="res-roles mx-0 w-100">
								<div
									onClick={() => push("/profile")}
									className="mb-0 d-flex justify-content-between mt-3 ml-0"
								>
									Manage Profile
									<LinkIcon />
								</div>
							</div>
						</div>

						<section className="res-wrapper">
							<h3 className="res-user">Settings</h3>
							<div className="res-roles">
								<div
									className="d-flex align-items-center"
									onClick={() => push("/change_password")}
								>
									<p className="mb-0 mx-0">Change Password</p>
								</div>
							</div>
							<div className="res-roles">
								<div
									onClick={() =>
										window.open(
											`${TENECE_SUPPORT_URL}`,
											"_blank"
										)
									}
								>
									<p className="mb-0 mx-0">Contact Support</p>
								</div>
							</div>
						</section>

						<section>
							<p
								className="mb-0 res-role-txt mt-3"
								onClick={() => setSignOutModal(true)}
							>
								Logout
							</p>
						</section>
					</section>
				</div>
			) : (
				<Button
					onClick={() => push("/login")}
					data-cy="default"
					buttonClass="primary"
					label="Login"
				/>
			)}
		</section>
	);
};

export { GlobalMenu };
