const baseUrl = "PGApplication";

export const verifyPGPaymentUrl = (rrr) =>
	`${baseUrl}/verify-payment?rrr=${rrr}`;
export const submitStep1ApplicationUrl = () => `${baseUrl}/step-1`;
export const submitStep2ApplicationUrl = () => `${baseUrl}/step-2`;
export const addOrUpdatePGProgrammeUrl = () =>
	`${baseUrl}/add-or-update-pg-programme`;
export const addOrUpdatePGNextOfKinAndBasicInfoUrl = () =>
	`${baseUrl}/add-or-update-pg-next-of-kin-and-basic-information`;
export const addOrUpdatePGOlevelResultUrl = () =>
	`${baseUrl}/add-or-update-pg-application-olevel-details`;
export const addOrUpdatePGEducationAndHonoursUrl = () =>
	`${baseUrl}/add-or-update-pg-education-and-honours`;
export const addOrUpdatePGCertificateUrl = () =>
	`${baseUrl}/add-or-update-pg-certificate`;
export const addOrUpdatePGEmploymentAndRefereeUrl = () =>
	`${baseUrl}/add-or-update-pg-employment-and-referee`;
export const addOrUpdatePGLanguageAndOtherInfoUrl = () =>
	`${baseUrl}/add-or-update-pg-language-and-other-information`;
export const getPGRefereeStatusUrl = (rrr) =>
	`${baseUrl}/pg-referee-status?rrr=${rrr}`;
export const getPGTrackingVerificationUrl = (rrr) =>
	`${baseUrl}/reprint-pg-application?rrr=${rrr}`;
export const getPGTranscriptRequestUrl = (rrr) =>
	`${baseUrl}/pg-transcript-request?rrr=${rrr}`;
export const getRefereeInfoUrl = (passCode) =>
	`${baseUrl}/pg-referee-information?passCode=${passCode}`;
export const getRefereePrintOutUrl = (refereeEmail, applicantId) =>
	`${baseUrl}/pg-referee-printout?refereeEmail=${refereeEmail}&applicantId=${applicantId}`;
export const getRefereeRatingOptionsUrl = () => `${baseUrl}/referee-ratings`;
export const submitRefereeReviewUrl = () => `${baseUrl}/pg-referee-review`;
export const updatePGRefereeUrl = (refereeId) =>
	`${baseUrl}/update-pg-referee?refereeId=${refereeId}`;
