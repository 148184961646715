import styles from "./auth_style.module.css";
import img from "../../assets/images/loginImg.png";
import { Bubbles, WelecomeCircle } from "../../assets/svgs";

export const AuthPageBackgroundWrapper = ({ children }) => {
	return (
		<div className={`d-flex ${styles.background}`}>
			<div className={`${styles.white}`}>{children}</div>
			<div className={styles.imageContainer}>
				<div className={styles.inner_image_container}>
					<Bubbles className={styles.bubbles} />
					<h4>
						Log in to explore, learn, and
						<br /> shape your future.
					</h4>
					<div className={styles.inner_inner_image_container}>
						<img src={img} alt="" />
					</div>
					<WelecomeCircle className={styles.welcome_circle} />
				</div>
			</div>
		</div>
	);
};
