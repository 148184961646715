const trimString = (str) => str.trimEnd();

export const removeTrailingSpaces = (data) => {
	if (typeof data !== "object" || data === null) return data;

	return Object.keys(data).reduce((acc, key) => {
		const value = data[key];
		acc[key] = typeof value === "string" ? trimString(value) : value;
		return acc;
	}, {});
};
